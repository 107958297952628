body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.btn-primary{
  background-color:  #5cb85c !important;
}

@media only screen and (max-width: 600px) {
  body {
    
  }
}
