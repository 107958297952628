.sidebar {
    background: #ffffff;
    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 1; /* Stay on top */
    top: 0;
    left: 0;
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
}
.sidebar a {
    text-decoration: none;
    display: block;
    transition: 0.3s;
  }
  .sidebar a:hover {
    color: #32620e;
  }
  .content{
    padding: 15px;
    margin: 0 auto;
  }
    .form-check-label{
        font-size: 18px;
        font-weight: bold;
    }
    .form-check-input{
        height: 100px;
    }
  .tit-pag{
    font-size: 25px;
    color: #32620e;
    text-align: center;
    margin: 0 auto;
  }
  .bemvindo{
    text-align: center;
    margin-top: -20px;
  }
  .lbl-atendimento{
    color: rgb(34, 34, 34) !important;
    font-size: 18px;
  }
  .input-form{
  }
  .form-atendimento{
    display: inline;
  }
h1 {
    color: #32620e;
    margin: 50px auto;
    font-size: 20px;
}
.menu1 {
    margin-bottom: 40px;
    margin: 30px auto;
    text-align: center;
}
h2 {
    color: #32620e;
}
h3 {
    color: #32620e;
}
.bemvindouser{
    color: #32620e;
    margin: 30px auto;
    text-align: center;
    font-size: 20px;
}
.fullCalendar{
    width: 78vw; 
    text-decoration: none;
}
.react-calendar__month-view__weekdays__weekday abbr{
    text-decoration: none;
}
.fc-button
{
  background-color: #32620e !important;
}

.imgUsuario{
    width: 5vw; 
    border-radius: 50%;
    margin-top: -30px;
}

.modal {
    position: absolute;
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    border: 1px solid rgb(223, 223, 223);
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    width: 50vw;
    text-align: center;
    margin: 0 auto;
  }
  
  .modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.164);
  }

  .modal-content{
    background-color: #ebebeb;
    height: 100%;
    width: 100%;
    text-align: center;
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
  }
  
  .ReactModal__Overlay--after-open{
    opacity: 1;
  }
  
  .ReactModal__Overlay--before-close{
    opacity: 0;
  }

  .card-group{
    margin-top: 20px;
  }
  .card{
    margin-right: 20px;
  }
  .navbar{
    width: 75vw;
  }

  .fc-col-header-cell-cushion {

  }
  .fc-daygrid-dot-event{

  }
  .titPag{
    margin: 0 auto;
    padding: 8px;
    height: 80px;
    width: 75vw;
    color: #000000;
  }
  
  .card-deck{
    display: inline;
  }
  .card-header{
    display: inline-flex;
  }
  .card-header h5 b{
    font-size: 17px;
    color: rgb(255, 255, 255);
  }
  .card-header h5{
    font-size: 17px;
    padding-top: 10px;
    color: rgb(255, 255, 255);
  }

  .card-text{
    font-size: 14px;
  }
  
  @media only screen and (max-width: 600px) {
    body {
      
    }
  }
  @-webkit-keyframes bounceOutRight {
    0% {
       -webkit-transform: translateX(0);
       transform: translateX(0);
    }
    100% {
       -webkit-transform: translateX(100%);
       transform: translateX(100%);
    }
 }
 
 @-webkit-keyframes bounceOutLeft {
    0% {
       -webkit-transform: translateX(0);
       transform: translateX(0);
    }
    100% {
       -webkit-transform: translateX(-100%);
       transform: translateX(-100%);
    }
 }

 .card-columns {
  @include media-breakpoint-only(lg) {
    column-count: 4;
  }
  @include media-breakpoint-only(xl) {
    column-count: 5;
  }
}





body {
  background-color: #eee
}

.card {
  border: none;
  border-radius: 10px;
  min-height: 260px;
}

.c-details span {
  font-weight: 300;
  font-size: 13px
}

.icon {
  width: 50px;
  height: 50px;
  background-color: #eee;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 39px
}

.color_yellow{
  color: #ffc815;
}
.color_blue{
  color: #020094;
}
.color_green{
  color: #006d36;
}

.color_blue span {
  background-color: #ecf4ff;
  min-width: 60px;
  height: 25px;
  padding: 5px;
  margin-top: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center
}
.color_yellow span {
  background-color: #fffbec;
  height: 25px;
  min-width: 60px;
  padding: 5px;
  margin-top: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center
}
.color_green span {
  background-color: #ecfff2;
  height: 25px;
  min-width: 60px;
  padding: 5px;
  margin-top: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center
}

.progress {
  height: 10px;
  border-radius: 10px
}

.progress div {
  background-color: red
}

.text1 {
  font-size: 14px;
  font-weight: 600
}

.text2 {
  color: #a5aec0
}

.titDashboard{
  color: #002f6dde;
}

.containerRelogio {
  display: flex;
  justify-content: center;
  align-items: center;
}

.clock {
  align-items: center;
  color: #002f6dde;
  display: flex;
  font-size: 30px;
}

.floatClock{
  position:fixed;
  width:150px;
  height:20px;
  top:20px;
  right:40px;
  color:#FFF;
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid #ffffff;
  border-radius: 20px;
  text-align:center;
  font-size:30px;
  z-index:1000;
  display: flex;
  justify-content: center;
  align-items: center;
}